import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/dashboard/Index.vue";
import Cookie from "js-cookie";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    meta: {
      auth: true,
    },
    component: Dashboard,
  },
  {
    path: "/report-per-product",
    name: "ReportPerProduct",
    meta: {
      auth: true,
      admin_manager_access: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "report-per-product" */ "../views/report-per-product/Index.vue"
      ),
  },
  {
    path: "/report-per-product/:id",
    name: "ReportPerProductDetail",
    meta: {
      auth: true,
      admin_manager_access: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "report-per-product-detail" */ "../views/report-per-product/Detail.vue"
      ),
  },
  {
    path: "/products",
    name: "Products",
    meta: {
      auth: true,
      admin_invoice_access: true,
    },
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/products/Index.vue"),
  },
  {
    path: "/products/create",
    name: "CreateProduct",
    meta: {
      auth: true,
      admin_invoice_access: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "create-product" */ "../views/products/Create.vue"
      ),
  },
  {
    path: "/products/edit/:id",
    name: "EditProduct",
    meta: {
      auth: true,
      admin_invoice_access: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "edit-product" */ "../views/products/Edit.vue"
      ),
  },
  {
    path: "/customers",
    name: "Customers",
    meta: {
      auth: true,
      admin_manager_access: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "customers" */ "../views/customers/Index.vue"
      ),
  },
  {
    path: "/customers/create",
    name: "CreateCustomer",
    meta: {
      auth: true,
      admin_manager_access: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "create-customer" */ "../views/customers/Create.vue"
      ),
  },
  {
    path: "/customers/edit/:id",
    name: "EditCustomer",
    meta: {
      auth: true,
      admin_manager_access: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "edit-customer" */ "../views/customers/Edit.vue"
      ),
  },
  {
    path: "/users",
    name: "Users",
    meta: {
      auth: true,
      admin_manager_access: true,
    },
    // route level code-splitting
    // this generates a separate chunk (users.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/users/Index.vue"),
  },
  {
    path: "/users/create",
    name: "CreateUser",
    meta: {
      auth: true,
      owner: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "create-users" */ "../views/users/Create.vue"
      ),
  },
  {
    path: "/users/edit/:id",
    name: "EditUser",
    meta: {
      auth: true,
      admin_manager_access: true,
    },
    component: () =>
      import(/* webpackChunkName: "edit-users" */ "../views/users/Edit.vue"),
  },
  {
    path: "/transactions",
    name: "Transactions",
    meta: {
      auth: true,
      admin_invoice_access: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "transactions" */ "../views/transactions/Index.vue"
      ),
  },
  {
    path: "/transactions/history",
    name: "TransactionsHistory",
    meta: {
      auth: true,
      admin_administration_access: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "transactions-history" */ "../views/transactions/History.vue"
      ),
  },
  {
    path: "/transactions/detail/:id",
    name: "TransactionsDetail",
    meta: {
      auth: true,
      admin_administration_access: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "transactions-detail" */ "../views/transactions/Detail.vue"
      ),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = Cookie.get("adpos_is_login");

  if (to.matched.some((record) => record.meta.auth) && !loggedIn) {
    next("/login");
    return;
  }

  next();
});

router.beforeEach((to, from, next) => {
  const isOwner = Cookie.get("adpos_user_owner");

  if (to.matched.some((record) => record.meta.owner)) {
    if (isOwner == 0) {
      next("/users");
      return;
    }
  }

  next();
});

router.beforeEach((to, from, next) => {
  const isOwner = Cookie.get("adpos_user_role") === "owner";
  const isAdminManager = Cookie.get("adpos_user_role") === "admin_manager";

  if (to.matched.some((record) => record.meta.admin_manager_access)) {
    if (!isOwner && !isAdminManager) {
      next("/"); // Redirect to dashboard or any other appropriate route
      return;
    }
  }
  next();
});

router.beforeEach((to, from, next) => {
  const isOwner = Cookie.get("adpos_user_role") == "owner";
  const isAdminManager = Cookie.get("adpos_user_role") == "admin_manager";
  const isAdminInvoice = Cookie.get("adpos_user_role") == "admin_invoice";

  if (to.matched.some((record) => record.meta.admin_invoice_access)) {
    if (!isOwner && !isAdminManager && !isAdminInvoice) {
      next("/"); // Redirect to dashboard or any other appropriate route
      return;
    }
  }
  next();
});

router.beforeEach((to, from, next) => {
  const isOwner = Cookie.get("adpos_user_role") == "owner";
  const isAdminManager = Cookie.get("adpos_user_role") == "admin_manager";
  const isAdminInvoice = Cookie.get("adpos_user_role") == "admin_invoice";
  const isAdminAdministration =
    Cookie.get("adpos_user_role") == "admin_administration";

  if (to.matched.some((record) => record.meta.admin_administration_access)) {
    if (
      !isOwner &&
      !isAdminManager &&
      !isAdminInvoice &&
      !isAdminAdministration
    ) {
      next("/"); // Redirect to dashboard or any other appropriate route
      return;
    }
  }
  next();
});

export default router;
