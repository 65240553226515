<template>
  <navbar />
  <page-title title="Dashboard" />
  <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <p class="text-sm text-gray-900">
          Welcome! Please choose one of the menu above to start using this
          Application
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import PageTitle from "@/components/PageTitle";

export default {
  name: "Dashboard",
  components: {
    Navbar,
    PageTitle,
  },
};
</script>
