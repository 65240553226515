import CustomerProduct from "@/api/CustomerProduct";
import Customer from "@/api/Customer";
import Product from "@/api/Product";

export default {
  namespaced: true,

  state: {
    form: {},
    customersProducts: [],
    customersDropdown: [],
    productsDropdown: [],
    errors: [],
    loading: false,
    modalCreate: false,
    modalEdit: false,
    modalDelete: {
      isOpen: false,
      selectedId: null,
      selectedCustomerId: null,
    },
  },

  mutations: {
    setCustomersDropdown(state, data) {
      state.customersDropdown = data;
    },

    setProductsDropdown(state, data) {
      state.productsDropdown = data;
    },

    setCustomersProducts(state, data) {
      state.customersProducts = data;
    },

    setErrors(state, error) {
      state.errors = error;
    },

    setModalCreate(state, data) {
      state.modalCreate = data;
    },

    setModalEdit(state, data) {
      state.modalEdit = data;
    },

    setModalDelete(state, data) {
      state.modalDelete = data;
    },

    setLoading(state, loading) {
      state.loading = loading;
    },

    setCustomersProductsForm(state, formData) {
      state.form = formData;
    },
  },

  actions: {
    async getCustomersDropdown({ commit }, payload) {
      try {
        commit("setLoading", true);
        const { data } = await Customer.getAll(payload);

        commit("setCustomersDropdown", data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
      }
    },

    async getProductsDropdown({ commit }, payload) {
      try {
        commit("setLoading", true);
        const { data } = await Product.getAll(payload);

        commit("setProductsDropdown", data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
      }
    },

    async getCustomersProducts({ commit }, payload) {
      try {
        commit("setLoading", true);
        const { data } = await CustomerProduct.getAll(payload);

        commit("setCustomersProducts", data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
      }
    },

    async storeCustomersProducts({ commit, dispatch }, payload) {
      try {
        commit("setLoading", true);
        await CustomerProduct.store(payload.form_data);

        commit("setLoading", false);
        commit("setErrors", []);
        commit("setCustomersProductsForm", {});
        commit("setModalCreate", false);

        if (payload.by == "product_id") {
          dispatch("getCustomersProducts", {
            page: 1,
            search: "",
            id: payload.form_data.product_id,
            by: "product_id",
          });
        } else {
          dispatch("getCustomersProducts", {
            page: 1,
            search: "",
            id: payload.form_data.customer_id,
            by: "customer_id",
          });
        }
      } catch (error) {
        commit("setErrors", error.response.data);
        commit("setLoading", false);
      }
    },

    async editCustomersProducts({ commit }, payload) {
      try {
        commit("setErrors", []);
        commit("setLoading", true);
        const response = await CustomerProduct.getById(payload);

        commit("setCustomersProductsForm", response.data.data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
      }
    },

    async updateCustomersProducts({ commit, dispatch }, payload) {
      try {
        commit("setLoading", true);
        await CustomerProduct.update(payload.form_data);

        commit("setLoading", false);
        commit("setErrors", []);
        commit("setCustomersProductsForm", {});
        commit("setModalEdit", false);

        if (payload.by == "product_id") {
          dispatch("getCustomersProducts", {
            page: 1,
            search: "",
            id: payload.form_data.product_id,
            by: "product_id",
          });
        } else {
          dispatch("getCustomersProducts", {
            page: 1,
            search: "",
            id: payload.form_data.customer_id,
            by: "customer_id",
          });
        }
      } catch (error) {
        commit("setErrors", error.response.data);
        commit("setLoading", false);
      }
    },

    async deleteCustomersProducts({ commit, dispatch }, payload) {
      try {
        commit("setErrors", []);
        commit("setLoading", true);

        await CustomerProduct.delete(payload);

        dispatch("getCustomersProducts", {
          page: 1,
          search: "",
          id: payload.customerId,
          by: "customer_id",
        });

        commit("setLoading", false);

        commit("setModalDelete", {
          isOpen: false,
          selectedId: null,
          selectedCustomerId: null,
        });
      } catch (error) {
        commit("setLoading", false);
        commit("setModalDelete", {
          isOpen: false,
          selectedId: null,
          selectedCustomerId: null,
        });
      }
    },

    openModalCreate({ commit }, payload) {
      commit("setErrors", []);
      commit("setCustomersProductsForm", {});
      commit("setModalCreate", payload);
    },

    openModalEdit({ commit }, payload) {
      commit("setErrors", []);
      commit("setModalEdit", payload);
    },

    openModalDelete({ commit }, payload) {
      commit("setErrors", []);
      commit("setModalDelete", payload);
    },
  },
};
