import Auth from "@/api/Auth";
import Cookie from "js-cookie";
import router from "@/router";

export default {
  namespaced: true,

  state: {
    user: {},
    form: {
      email: "",
      password: "",
    },
    loading: false,
    errors: [],
  },

  mutations: {
    setUserData(state, userData) {
      state.user = userData;
      Cookie.set("adpos_is_login", true, {
        expires: 86400,
        sameSite: "lax",
      });

      Cookie.set("adpos_user_id", userData.id, {
        expires: 86400,
        sameSite: "lax",
      });

      Cookie.set("adpos_user_owner", userData.owner, {
        expires: 86400,
        sameSite: "lax",
      });

      Cookie.set("adpos_user_role", userData.role, {
        expires: 86400,
        sameSite: "lax",
      });
    },

    clearUserData() {
      Cookie.remove("adpos_is_login", {
        expires: 86400,
        sameSite: "lax",
      });

      Cookie.remove("adpos_user_id", {
        expires: 86400,
        sameSite: "lax",
      });

      Cookie.remove("adpos_user_owner", {
        expires: 86400,
        sameSite: "lax",
      });

      Cookie.remove("adpos_user_role", {
        expires: 86400,
        sameSite: "lax",
      });

      router.push({ name: "Login" });
    },

    setErrors(state, error) {
      state.errors = error;
    },

    setLoading(state, loading) {
      state.loading = loading;
    },
  },

  actions: {
    async login({ commit }, credentials) {
      try {
        commit("setLoading", true);
        const { data } = await Auth.login(credentials);

        commit("setUserData", data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        commit("setErrors", error.response.data);
      }
    },

    async logout({ commit }) {
      await Auth.logout();
      commit("clearUserData");
    },
  },

  getters: {
    isLogged: (state) => !!state.user,
  },
};
