import Transaction from "@/api/Transaction";
import TransactionHistory from "@/api/TransactionHistory";

export default {
  namespaced: true,

  state: {
    transactions: [],
    transaction: {},
    form: {},
    errors: [],
    loading: false,
    loadingUpdateBulk: false,
    sumGrandTotal: 0,
    showAddProductModal: false,
    showEditProductModal: false,
    showEditPriceModal: false,
    showEditNotesModal: false,
    isSuccessUpdateNotes: false, // success bulk update note
    selectedTransactionIds: [],
    isSelectAllEnabled: true, // Track "Select All" status globally
    activePage: null, // Track the current page where "Select All" is active
  },

  mutations: {
    setTransactions(state, data) {
      state.transactions = data;
    },

    setSumGrandTotal(state, data) {
      state.sumGrandTotal = data;
    },

    setTransaction(state, data) {
      state.transaction = data;
    },

    setErrors(state, error) {
      state.errors = error;
    },

    setLoading(state, loading) {
      state.loading = loading;
    },

    setLoadingUpdateBulk(state, loading) {
      state.loadingUpdateBulk = loading;
    },

    setAddProductModal(state, data) {
      state.showAddProductModal = data;
    },

    setEditProductModal(state, data) {
      state.showEditProductModal = data;
    },

    setEditPriceModal(state, data) {
      state.showEditPriceModal = data;
    },

    setEditNotesModal(state, data) {
      state.showEditNotesModal = data;
    },

    setForm(state, formData) {
      state.form = formData;
    },

    setIsSuccessUpdateNotes(state, data) {
      state.isSuccessUpdateNotes = data;
    },

    setSelectedTransactionIds(state, data) {
      const index = state.selectedTransactionIds.indexOf(data.id);
      if (index !== -1) {
        state.selectedTransactionIds.splice(index, 1);
      } else {
        state.selectedTransactionIds.push(data.id);
      }
    },

    setSelectedTransactionIdsAll(state, transactionIds) {
      state.selectedTransactionIds = transactionIds;
    },

    setClearSelectedTransactionIds(state) {
      state.selectedTransactionIds = [];
    },

    setSelectAllStatus(state, status) {
      state.isSelectAllEnabled = status;
    },

    setActivePage(state, pageId) {
      state.activePage = pageId;
    },

    clearActivePage(state) {
      state.activePage = null;
    },
  },

  actions: {
    async getTransactions({ commit }, payload) {
      try {
        commit("setLoading", true);
        const { data } = await Transaction.getAll(payload);

        commit("setTransactions", data);
        commit("setLoading", false);

        commit("setClearSelectedTransactionIds");
        commit("clearActivePage");
        commit("setSelectAllStatus", true);
      } catch (error) {
        commit("setLoading", false);

        commit("setClearSelectedTransactionIds");
        commit("clearActivePage");
        commit("setSelectAllStatus", true);
      }
    },

    async sumGrandTotal({ commit }, payload) {
      try {
        commit("setLoading", true);
        const { data } = await Transaction.sumGrandTotal(payload);

        commit("setSumGrandTotal", data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
      }
    },

    async getById({ commit }, payload) {
      try {
        commit("setLoading", true);
        const response = await Transaction.getById(payload);

        commit("setTransaction", response.data.data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
      }
    },

    async changePaymentStatus({ commit }, payload) {
      try {
        commit("setLoading", true);
        const response = await Transaction.update({
          transaction: payload.transaction,
          payment_status: payload.payment_status,
          payment_method: payload.payment_method,
        });

        commit("setLoading", false);
        commit("setErrors", []);
        commit("setTransaction", response.data.data);
      } catch (error) {
        commit("setErrors", error.response.data);
        commit("setLoading", false);
      }
    },

    async changeTransactionDate({ commit }, payload) {
      try {
        commit("setLoading", true);
        const response = await Transaction.update({
          transaction: payload.transaction,
          created_at: payload.created_at,
        });

        commit("setLoading", false);
        commit("setErrors", []);
        commit("setTransaction", response.data.data);
      } catch (error) {
        commit("setErrors", error.response.data);
        commit("setLoading", false);
      }
    },

    async changeTransactionNote({ commit }, payload) {
      try {
        commit("setLoading", true);
        const response = await Transaction.update({
          transaction: payload.transaction,
          note: payload.note,
        });

        commit("setLoading", false);
        commit("setErrors", []);
        commit("setTransaction", response.data.data);
      } catch (error) {
        commit("setErrors", error.response.data);
        commit("setLoading", false);
      }
    },

    openAddProductModal({ commit }, payload) {
      commit("setAddProductModal", payload);
    },

    openEditProductModal({ commit }, payload) {
      commit("setEditProductModal", payload);

      commit("setErrors", []);
      commit("setForm", {});
    },

    openEditPriceModal({ commit }, payload) {
      commit("setEditPriceModal", payload);

      commit("setErrors", []);
      commit("setForm", {});
    },

    openEditNotesModal({ commit }, payload) {
      commit("setEditNotesModal", payload);

      commit("setErrors", []);
      commit("setForm", {});
    },

    async changeQuantity({ commit, dispatch }, payload) {
      try {
        commit("setLoading", true);
        const detail = await TransactionHistory.updateQuantity({
          id: payload.selected_detail_id,
          new_quantity: payload.form_data.new_quantity,
          update_stock: payload.form_data.update_stock,
          otp_code: payload.form_data.otp_code,
        });

        dispatch("getById", detail.data.data.transaction_id);

        commit("setLoading", false);
        commit("setErrors", []);
        commit("setEditProductModal", false);
      } catch (error) {
        commit("setErrors", error.response.data);
        commit("setLoading", false);
      }
    },

    async sendOtpQuantityInvoice({ commit }, payload) {
      try {
        commit("setLoading", true);
        await TransactionHistory.sendOtpQuantityInvoice({
          id: payload.detail_transaction_id,
          transaction_id: payload.transaction_id,
          detail_transaction_id: payload.detail_transaction_id,
          transaction_code: payload.transaction_code,
          customer_name: payload.customer_name,
          product_name: payload.product_name,
        });

        commit("setLoading", false);
        commit("setErrors", []);
      } catch (error) {
        console.log("error otp ", error.response);
        commit("setErrors", error.response);
        commit("setLoading", false);
      }
    },

    async changePrice({ commit, dispatch }, payload) {
      try {
        commit("setLoading", true);
        const detail = await TransactionHistory.updatePrice({
          id: payload.selected_detail_id,
          new_price: payload.form_data.new_price,
        });

        dispatch("getById", detail.data.data.transaction_id);

        commit("setLoading", false);
        commit("setErrors", []);
        commit("setEditPriceModal", false);
      } catch (error) {
        commit("setErrors", error.response.data);
        commit("setLoading", false);
      }
    },

    async toggleSelectedTransactionId({ commit, state }, payload) {
      commit("setSelectedTransactionIds", payload);
      console.log(state.selectedTransactionIds);
    },

    async toggleSelectAll({ commit, state }, { transactionIds, pageId }) {
      if (state.selectedTransactionIds.length === transactionIds.length) {
        commit("setClearSelectedTransactionIds");
        commit("clearActivePage");
        commit("setSelectAllStatus", true);
      } else {
        commit("setSelectedTransactionIdsAll", transactionIds);
        commit("setActivePage", pageId);
        commit("setSelectAllStatus", false);
      }

      console.log(state.selectedTransactionIds);
    },

    async clearSelectedTransactionIds({ commit }) {
      commit("setClearSelectedTransactionIds");
    },

    async clearSuccessUpdateNotes({ commit }) {
      commit("setIsSuccessUpdateNotes", false);
    },

    async updatePaymentStatuses({ commit }, payload) {
      try {
        commit("setLoadingUpdateBulk", true);
        await Transaction.updatePaymentStatuses({
          selectedIds: payload.ids,
          payment_method: payload.paymentMethod,
          note: payload.note,
        });

        commit("setIsSuccessUpdateNotes", true);
        commit("setLoadingUpdateBulk", false);
        commit("setErrors", []);
        commit("setForm", {});

        commit("setClearSelectedTransactionIds");
        commit("clearActivePage");
        commit("setSelectAllStatus", true);
      } catch (error) {
        commit("setIsSuccessUpdateNotes", false);
        commit("setErrors", error.response.data);
        commit("setLoadingUpdateBulk", false);
        commit("setForm", {});

        commit("setClearSelectedTransactionIds");
        commit("clearActivePage");
        commit("setSelectAllStatus", true);
      }
    },
  },
};
