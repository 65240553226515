import Category from "@/api/Category";

export default {
  namespaced: true,

  state: {
    categories: [],
    errors: [],
    loading: false,
  },

  mutations: {
    setCategories(state, data) {
      state.categories = data;
    },

    setErrors(state, error) {
      state.errors = error;
    },

    setLoading(state, loading) {
      state.loading = loading;
    },
  },

  actions: {
    async getCategories({ commit }) {
      try {
        commit("setLoading", true);
        const { data } = await Category.getAll();

        commit("setCategories", data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
      }
    },
  },
};
