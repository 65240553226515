import _ from "lodash";
import router from "@/router";
import Transaction from "@/api/Transaction";

export default {
  namespaced: true,

  state: {
    customer: {
      customer_id: null,
      name: "",
      type: "",
    },
    transaction: {
      customer_id: null,
      grand_total: 0,
      payment_method: "",
      payment_status: "",
      note: "",
    },
    cart: [],
    errors: [],
    loading: false,
    showPreviousTransactionModal: false,
  },

  mutations: {
    // Set customer
    setCustomer(state, payload) {
      state.customer = payload;
      state.transaction.customer_id = payload.customer_id;
    },

    // Add cart item
    addCart(state, payload) {
      const index = _.findIndex(state.cart, { product_id: payload.product_id });

      if (index > -1) {
        state.cart[index].quantity += payload.quantity;

        // Update total of the item
        const newTotal = state.cart[index].price * state.cart[index].quantity;
        state.cart[index].total = newTotal;
      } else {
        // If selected from product listing
        state.cart = [...state.cart, payload];
      }

      // Calculate grand total
      state.transaction.grand_total = state.cart.reduce((total, current) => {
        return total + current.total;
      }, 0);
    },

    // Delete all cart, grand total, payment method, payment status, and note
    deleteAllCart(state) {
      state.cart = [];
      state.transaction.grand_total = 0;
      state.transaction.payment_method = "";
      state.transaction.payment_status = "";
      state.transaction.note = "";
      state.errors = [];
      state.loading = false;
    },

    // Update quantity
    updateCart(state, payload) {
      const index = _.findIndex(state.cart, {
        product_id: payload.item.product_id,
      });

      if (index > -1) {
        if (payload.type == "increment") {
          state.cart[index].quantity = payload.item.quantity + 1;
        } else if (payload.type == "decrement") {
          state.cart[index].quantity = payload.item.quantity - 1;
        }

        // Make sure qty less than stock
        if (state.cart[index].quantity > state.cart[index].stock) {
          alert("Quantity must less than stock.");
          state.cart[index].quantity = 1;
        }

        if (state.cart[index].quantity > 0) {
          // Update total of the item
          let newTotal = state.cart[index].price * state.cart[index].quantity;
          state.cart[index].total = newTotal;

          // Calculate grand total
          state.transaction.grand_total = state.cart.reduce(
            (total, current) => {
              return total + current.total;
            },
            0
          );
        }

        // Delete cart item when quantity value = 0
        if (state.cart[index].quantity <= 0) {
          state.cart = state.cart.filter((current) => {
            return current.product_id != state.cart[index].product_id;
          });

          // Calculate grand total
          state.transaction.grand_total = state.cart.reduce(
            (total, current) => {
              return total + current.total;
            },
            0
          );
        }
      }
    },

    // Update cart using input field
    updateCartUsingInput(state, payload) {
      const index = _.findIndex(state.cart, {
        product_id: payload.item.product_id,
      });

      if (index > -1) {
        // Make sure qty less than stock
        if (state.cart[index].quantity > state.cart[index].stock) {
          alert("Quantity must less than stock.");
          state.cart[index].quantity = 1;
        }

        // Update total of the item
        const newTotal = state.cart[index].price * state.cart[index].quantity;
        state.cart[index].total = newTotal;

        // Calculate grand total
        state.transaction.grand_total = state.cart.reduce((total, current) => {
          return total + current.total;
        }, 0);
      }
    },

    // Loading state
    setLoading(state, loading) {
      state.loading = loading;
    },

    /// Error state
    setErrors(state, error) {
      state.errors = error;
    },

    setPreviousTransactionModal(state, data) {
      state.showPreviousTransactionModal = data;
    },
  },

  actions: {
    // set customer
    setCustomer({ commit }, payload) {
      commit("setCustomer", payload);

      // Delete cart items
      if (payload.customer_id == null) {
        commit("deleteAllCart");
      }
    },

    // add cart
    addCart({ commit }, payload) {
      let total = 0;
      let quantity = payload.quantity;

      if (payload.quantity == undefined) {
        quantity = 1;
      }

      total = payload.price * quantity;

      const item = {
        product_id: payload.product.id,
        name: payload.product.name,
        price: payload.price,
        quantity: quantity,
        stock: payload.product.stock,
        total: total,
      };

      commit("addCart", item);
    },

    // Delete all cart
    deleteAllCart({ commit }) {
      commit("deleteAllCart");
    },

    // Update specific cart item
    updateCart({ commit }, payload) {
      commit("updateCart", payload);
    },

    updateCartUsingInput({ commit }, payload) {
      commit("updateCartUsingInput", payload);
    },

    // Send transaction data
    async saveTransaction({ commit }, payload) {
      try {
        commit("setLoading", true);
        const response = await Transaction.store(payload.transaction);

        const transactionID = response.data.data.id;
        let newDetail = payload.detail_transaction.map((item) => ({
          transaction_id: response.data.data.id,
          product_id: item.product_id,
          quantity: item.quantity,
          price: item.price,
          total: item.total,
        }));

        try {
          await Transaction.storeDetail({
            detail_transaction: newDetail,
          });

          commit("setLoading", false);
          commit("setErrors", []);
          commit("deleteAllCart");
          commit("setCustomer", {
            customer_id: null,
            name: "",
            type: "",
          });

          router.push({
            name: "TransactionsDetail",
            params: { id: transactionID },
          });
        } catch (error) {
          commit("setErrors", error.response.data);
          commit("setLoading", false);
        }
      } catch (error) {
        commit("setErrors", error.response.data);
        commit("setLoading", false);
      }
    },

    openPreviousTransactionModal({ commit }, payload) {
      commit("setPreviousTransactionModal", payload);
    },
  },
};
