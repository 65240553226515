import User from "@/api/User";
import router from "@/router";

export default {
  namespaced: true,

  state: {
    form: {
      name: "",
      email: "",
      password: "",
      owner: 0,
    },
    users: [],
    errors: [],
    loading: false,
  },

  mutations: {
    setUsers(state, data) {
      state.users = data;
    },

    setErrors(state, error) {
      state.errors = error;
    },

    setLoading(state, loading) {
      state.loading = loading;
    },

    setUserForm(state, formData) {
      state.form = formData;
    },
  },

  actions: {
    async getUsers({ commit }, payload) {
      try {
        commit("setLoading", true);
        const { data } = await User.getAll(payload);

        commit("setUsers", data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
      }
    },

    async storeUser({ commit }, payload) {
      try {
        commit("setLoading", true);
        await User.store(payload);

        commit("setLoading", false);
        commit("setErrors", []);
        commit("setUserForm", {});
        router.push({ name: "Users" });
      } catch (error) {
        commit("setErrors", error.response.data);
        commit("setLoading", false);
      }
    },

    async editUser({ commit }, payload) {
      try {
        commit("setErrors", []);
        commit("setLoading", true);
        const response = await User.getById(payload);

        commit("setUserForm", response.data.data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
      }
    },

    async updateUser({ commit }, payload) {
      try {
        commit("setLoading", true);
        await User.update(payload);

        commit("setLoading", false);
        commit("setErrors", []);
        commit("setUserForm", {});
        router.push({ name: "Users" });
      } catch (error) {
        commit("setErrors", error.response.data);
        commit("setLoading", false);
      }
    },

    resetUserForm({ commit }) {
      commit("setUserForm", {});
    },
  },
};
