import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async getAll(params) {
    let page = "";
    let search = "";

    if (params.page != undefined) {
      page = params.page;
    }

    if (params.search != undefined) {
      search = params.search;
    }

    await Csrf.getCookie();

    return Api.get(`/api/customers?page=${page}&search=${search}`);
  },

  async getById(id) {
    await Csrf.getCookie();

    return Api.get(`/api/customers/${id}`);
  },

  async store(data) {
    await Csrf.getCookie();

    return Api.post("/api/customers", data);
  },

  async update(data) {
    await Csrf.getCookie();

    return Api.put(`/api/customers/${data.id}`, data);
  },
};
