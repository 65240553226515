import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async getAll(params) {
    let page = "";
    let search = "";

    if (params.page != undefined) {
      page = params.page;
    }

    if (params.search != undefined) {
      search = params.search;
    }

    await Csrf.getCookie();

    return Api.get(`/api/products?page=${page}&search=${search}`);
  },

  async getDetailTransactionProduct(params) {
    let start_date = "";
    let end_date = "";

    if (params.start_date != undefined) {
      start_date = params.start_date;
    }

    if (params.end_date != undefined) {
      end_date = params.end_date;
    }

    await Csrf.getCookie();

    return Api.get(
      `/api/products/${params.id}/detail-transactions?start_date=${start_date}&end_date=${end_date}`
    );
  },

  async getById(id) {
    await Csrf.getCookie();

    return Api.get(`/api/products/${id}`);
  },

  async store(data) {
    await Csrf.getCookie();

    return Api.post("/api/products", data);
  },

  async update(data) {
    await Csrf.getCookie();

    return Api.put(`/api/products/${data.id}`, data);
  },
};
