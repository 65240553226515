<template>
  <router-view />
</template>

<script>
import Api from "@/api/Api";

export default {
  mounted() {
    Api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }
        return Promise.reject(error);
      }
    );
  },
};
</script>
