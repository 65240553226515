import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async getAll(params) {
    let page = "";
    let search = "";
    let payment_status = "";
    let start_date = "";
    let end_date = "";
    let term_of_payment = "";
    let customer_id = "";

    if (params.page != undefined) {
      page = params.page;
    }

    if (params.search != undefined) {
      search = params.search;
    }

    if (params.payment_status != undefined) {
      payment_status = params.payment_status;
    }

    if (params.start_date != undefined) {
      start_date = params.start_date;
    }

    if (params.end_date != undefined) {
      end_date = params.end_date;
    }

    if (params.term_of_payment != undefined) {
      term_of_payment = params.term_of_payment;
    }

    if (params.customer_id != undefined) {
      customer_id = params.customer_id;
    }

    await Csrf.getCookie();

    return Api.get(
      `/api/transactions?page=${page}&search=${search}&payment_status=${payment_status}&start_date=${start_date}&end_date=${end_date}&term_of_payment=${term_of_payment}&customer_id=${customer_id}`
    );
  },

  async sumGrandTotal(params) {
    let search = "";
    let payment_status = "";
    let start_date = "";
    let end_date = "";
    let term_of_payment = "";

    if (params.search != undefined) {
      search = params.search;
    }

    if (params.payment_status != undefined) {
      payment_status = params.payment_status;
    }

    if (params.start_date != undefined) {
      start_date = params.start_date;
    }

    if (params.end_date != undefined) {
      end_date = params.end_date;
    }

    if (params.term_of_payment != undefined) {
      term_of_payment = params.term_of_payment;
    }

    await Csrf.getCookie();

    return Api.get(
      `/api/transactions/sum?search=${search}&payment_status=${payment_status}&start_date=${start_date}&end_date=${end_date}&term_of_payment=${term_of_payment}`
    );
  },

  async getById(id) {
    await Csrf.getCookie();

    return Api.get(`/api/transactions/${id}`);
  },

  async store(data) {
    await Csrf.getCookie();

    return Api.post("/api/transactions", data);
  },

  async storeDetail(data) {
    await Csrf.getCookie();

    return Api.post("/api/transactions/detail", data);
  },

  async update(data) {
    await Csrf.getCookie();

    return Api.put(`/api/transactions/${data.transaction.id}`, data);
  },

  async updatePaymentStatuses(data) {
    await Csrf.getCookie();

    return Api.post(`/api/transactions/update-payment-statuses`, data);
  },
};
