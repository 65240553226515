import Product from "@/api/Product";
import router from "@/router";

export default {
  namespaced: true,

  state: {
    form: {
      name: "",
      stock: "",
      category_id: "",
    },
    products: [],
    detailTransactionProduct: {},
    errors: [],
    loading: false,
  },

  mutations: {
    setProducts(state, data) {
      state.products = data;
    },

    setErrors(state, error) {
      state.errors = error;
    },

    setLoading(state, loading) {
      state.loading = loading;
    },

    setProductForm(state, formData) {
      state.form = formData;
    },

    setDetailTransactionProduct(state, data) {
      state.detailTransactionProduct = data;
    },
  },

  actions: {
    async getProducts({ commit }, payload) {
      try {
        commit("setLoading", true);
        const { data } = await Product.getAll(payload);

        commit("setProducts", data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
      }
    },

    async getDetailTransactionProduct({ commit }, payload) {
      try {
        commit("setLoading", true);
        const { data } = await Product.getDetailTransactionProduct(payload);

        commit("setDetailTransactionProduct", data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
      }
    },

    async storeProduct({ commit }, payload) {
      try {
        commit("setLoading", true);
        await Product.store(payload);

        commit("setLoading", false);
        commit("setErrors", []);
        commit("setProductForm", {});
        router.push({ name: "Products" });
      } catch (error) {
        commit("setErrors", error.response.data);
        commit("setLoading", false);
      }
    },

    async editProduct({ commit }, payload) {
      try {
        commit("setErrors", []);
        commit("setLoading", true);
        const response = await Product.getById(payload);

        commit("setProductForm", response.data.data);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
      }
    },

    async updateProduct({ commit }, payload) {
      try {
        commit("setLoading", true);
        await Product.update(payload);

        commit("setLoading", false);
        commit("setErrors", []);
        commit("setProductForm", {});
        router.push({ name: "Products" });
      } catch (error) {
        commit("setErrors", error.response.data);
        commit("setLoading", false);
      }
    },

    resetProductForm({ commit }) {
      commit("setProductForm", {});
    },
  },
};
