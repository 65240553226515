import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async getAll(params) {
    let page = "";
    let search = "";
    let by = "";
    let id = "";

    if (params.page != undefined) {
      page = params.page;
    }

    if (params.search != undefined) {
      search = params.search;
    }

    if (params.by != undefined) {
      by = params.by;
    }

    if (params.id != undefined) {
      id = params.id;
    }

    await Csrf.getCookie();

    return Api.get(
      `/api/customers-products?page=${page}&search=${search}&by=${by}&id=${id}`
    );
  },

  async getById(id) {
    await Csrf.getCookie();

    return Api.get(`/api/customers-products/${id}`);
  },

  async store(data) {
    await Csrf.getCookie();

    return Api.post("/api/customers-products", data);
  },

  async update(data) {
    await Csrf.getCookie();

    return Api.put(`/api/customers-products/${data.id}`, data);
  },

  async delete(data) {
    await Csrf.getCookie();

    return Api.delete(`/api/customers-products/${data.id}`);
  },
};
