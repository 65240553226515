import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async updateQuantity(data) {
    await Csrf.getCookie();

    return Api.put(`/api/detail-transactions/${data.id}/quantity`, data);
  },

  async updatePrice(data) {
    await Csrf.getCookie();

    return Api.put(`/api/detail-transactions/${data.id}/price`, data);
  },

  async sendOtpQuantityInvoice(data) {
    await Csrf.getCookie();

    return Api.post(
      `/api/detail-transactions/${data.id}/send-otp-quantity-invoice`,
      data
    );
  },
};
