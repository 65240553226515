import { createStore } from "vuex";
import auth from "./modules/auth";
import users from "./modules/users";
import categories from "./modules/categories";
import products from "./modules/products";
import customers from "./modules/customers";
import customersProducts from "./modules/customersProducts";
import transactions from "./modules/transactions";
import transactionsHistory from "./modules/transactionsHistory";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    categories,
    products,
    customers,
    customersProducts,
    users,
    transactions,
    transactionsHistory,
  },
});
